//   /** (min-width: 640px) */
//   small: `(min-width: 640px)`,

//   /** (min-width: 768px) */
//   medium: `(min-width: 768px)`,

//   /** (min-width: 1024px) */
//   large: `(min-width: 1024px)`,

.themeA {
  --poll-background: #2268b8;
  --poll-option-1: #76ba6d;
  --poll-option-2: #ffac3b;
}

.themeB {
  --poll-background: #76ba6d;
  --poll-option-1: #2368b8;
  --poll-option-2: #ffa3b5;
}

.poll {
  background: var(--poll-background);
  position: relative;
  min-height: 574px;
  display: flex;
  padding: 24px;
  padding-top: 96px;
  margin-left: -24px;
  margin-right: -24px;
  flex-direction: column;
  align-items: flex-end;

  @media (min-width: 640px) {
    margin-left: -48px;
    margin-right: -48px;
  }

  @media (min-width: 1024px) {
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    flex-direction: column;
    min-height: 0;
    color: #fff;
    padding-top: auto;
    align-items: flex-start;
  }
}

.content {
  position: relative;
  z-index: 2;
  padding: 24px;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  border-top-right-radius: 40px;
  border-bottom-left-radius: 40px;
  margin-top: auto;

  @media (min-width: 640px) {
    padding: 48px;
  }

  @media (min-width: 1024px) {
    width: 50%;
    display: flex;
    align-items: flex-end;
    background: transparent;
    padding: 58px;
  }
}

.main {
  width: 100%;
}

.topline {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 0.8em;
  font-size: 18px;

  @media (min-width: 768px) {
    font-size: 22px;
  }
}

.name {
  font-size: 32px;
  font-weight: 700;
  margin: 0 0 24px 0;
  line-height: 1.1;

  @media (min-width: 640px) {
    font-size: 40px;
  }

  @media (min-width: 768px) {
    font-size: 48px;
    margin-bottom: 48px;
  }
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-position: top right;
}

.vote {
  background: #d2281b;
  color: #fff;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
  height: 128px;
  display: flex;
  overflow: hidden;
}

.options {
  display: flex;
  width: 100%;
}

.option {
  flex: 1;
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
  font-family: inherit;
  font-size: 16px;
  font-weight: bold;
  color: inherit;
  display: block;
  transition: all 0.2s ease-in-out;
  padding: 16px 8px;

  @media (min-width: 640px) {
    font-size: 26px;
    padding: 28px;
  }

  &:first-child {
    border-right: 2px solid #fff;
  }

  & > div {
    display: block;
    transition: transform 0.2s ease-in-out;
  }

  &:hover {
    /* background: rgba(255, 255, 255, 0.1); */
    flex: 1.1;

    & > div.optionName {
      transform: scale(1.1);
    }
  }
}

.optionVoted {
  cursor: not-allowed;

  &:first-child {
    background-color: var(--poll-option-1);
  }

  &:last-child {
    background-color: var(--poll-option-2);
  }
}

.optionPercentage {
  margin-bottom: 4px;
}

.optionName {
}

.optionNameVoted {
  font-size: 14px;
  transform: scale(1) !important;
}
